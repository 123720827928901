export default class Response {
  constructor(client) {
    this.client = client;
  }

  createQuestion(body) {
    return this.client.post(`/response`, body);
  }

  getResponses(service_name) {
    return this.client.get(`/response/service_name/${service_name}`);
  }

  fetchOneResponse(id) {
    return this.client.get(`/response/${id}`);
  }
  deleteResponse(id) {
    return this.client.delete(`/response/${id}`);
  }

  editResponse(body) {
    const id = body.id;
    return this.client.put(`/response/${id}`, body);
  }
}
