import "./App.css";
import Card from "./Card";

function Chat() {
  return (
    <div className="App">
      <Card />
    </div>
  );
}

export default Chat;
