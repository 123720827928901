import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Chat from "./Components/Chat";
import reportWebVitals from './reportWebVitals';
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import Payments from "./Components/Payments";
import Error from './Components/Error';
import Successful from './Components/Successful';
import Tnps from './Components/Tnps';

const router = createBrowserRouter([
  {
    path: "/",
    errorElement: <Error />,
    element: <Chat />,
  },
  {
    path: "/card-payment/:trxId",
    errorElement: <h2>Error</h2>,
    element: <Payments />,
  },
  {
    path: "/redirect",
    errorElement: <h2>An Error occurred</h2>,
    element: <Successful />,
  },
  {path:"/survey",
    element:<Tnps/>
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <RouterProvider router={router} />
   
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
