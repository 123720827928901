


export default class Payments {
  constructor(client) {
    this.client = client;
  }

  verifyTransaction(trxId) {
    return this.client.get(`/payment/verify-pay-with-card-url/${trxId}`);
  }

  completePyment(body){
   return this.client.post(`/payment/complete-pay-with-card`, body); 
  }

 
}
