

import React, { useEffect } from "react";
import {
  MDBContainer,
} from "mdb-react-ui-kit";

const Successful = () => {
  useEffect(() => {
    window.location.replace("https://www.swsc.co.sz/");
  }, []);
  return (
    <MDBContainer fluid className="py-3">
      <div
        style={{
          marginTop: "20%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      ></div>
    </MDBContainer>
  );
};

export default Successful;
