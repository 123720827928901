import React, { useEffect, useState } from "react";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.min.css";
import { useSearchParams, } from "react-router-dom";
import api from "../api";
import { json } from "./json";
import { MDBContainer, MDBSpinner } from "mdb-react-ui-kit";

var myCss = {
  navigation: {
    complete: "sv-btn sv-footer__complete-btn",
  },
};
function Tnps() {
 
  const [searchParams] = useSearchParams();
  let push_id = searchParams.get("push_id");
  let user_email = searchParams.get("identifier");
  const [questions, setQuestions] = useState([]);
  const [services, setServices] = useState({ id: "", services: [] });
  const [survey, setSurvey] = useState(new Model(json));
  const [error, setError] = useState(null)

  useEffect(() => {
    if (push_id) {
      getPushById(push_id);
    }
  }, [push_id]);

  useEffect(() => {
    if (services.services.length > 0) {
      getQuestions(services.services);
    }
  }, [services.services]);

  useEffect(() => {
    setSurvey(
      new Model(
        JSON.stringify({ elements: questions, showQuestionNumbers: "false" })
      )
    );
  }, [questions]);
 

  const getQuestions = (services) => {
    api.question
      .getQuestions(services)
      .then((res) => {
        if (res) {
          // res?.data?.data?.questions?.map((q) => {
          //   console.log(q)
          //  const page = survey.addNewPage("All questions")
          //  page.addNewQuestion(q.type, q.id)
          //  page.title =q.text
          // });
          const values = res?.data?.data?.questions?.map((q) => {
            const text = q.text
            const type = q.type;
            const mod = text[0].toUpperCase() + text.slice(1);
            return type === "rating_scale"
              ? {
                  displayMode: "buttons",
                  type: "rating",
                  description: q.service_name.replaceAll("_", " "),
                  isRequired: true,
                  name: q.id,
                  title: mod,
                  rateMin: 1,
                  rateMax: 10,
                  minRateDescription: {
                    default: "Very unlikely",
                    fr: "Très improbable",
                  },
                  maxRateDescription: {
                    default: "Very likely",
                    fr: "Très probable",
                  },
                  rateDescriptionLocation: "bottom",
                }
              : type === "close_ended" &&
                q.close_ended_metadata.kind === "Checkbox"
              ? {
                  type: "checkbox",

                  name: q.id,
                  title: mod,
                  description: q.service_name.replaceAll("_", " "),
                  choices: q?.close_ended_metadata?.text_options,
                  isRequired: true,
                  colCount: 1,
                  separateSpecialChoices: true,
                }
              : type === "close_ended" &&
                q.close_ended_metadata.kind === "Radio"
              ? {
                  type: "radiogroup",
                  name: q.id,
                  title: mod,
                  description: q.service_name.replaceAll("_", " "),
                  isRequired: true,
                  choices: q?.close_ended_metadata?.text_options,
                  colCount: 1,
                }
              : {
                  type: "text",
                  name: q.id,
                  title: mod,
                  description: q.service_name.replaceAll("_", " "),
                  isRequired: true,
                  
                };
          });
          setQuestions(values);
        }
      })
      .catch((err) => {});
  };

  survey.css = myCss;
  survey.onComplete.add((sender) => {
   console.log(sender.data);
    const responses = [];
    for (const key in sender.data) {
      const val = survey.getQuestionByName(key);
      responses.push({
        "question_id": key,
        "response": typeof val.value =="number"? `${val.value}`:val.value,
        "answered_by": user_email,
        "pushed_id": push_id,
      });
    }
  
    createResponse({responses})
  });

  const createResponse = (responses)=>{
    api.response
      .createQuestion(responses)
      .then((res) => {
        if (res) {
       //  window.location.replace("https://www.swsc.co.sz/");
        }
      })
      .catch((err) => {});
  }
  console.log(questions);
  const getPushById = (pid) => {
    api.push
      .fetchOnePush(pid)
      .then((res) => {
        if (res) {
          setServices({
            id: res?.data?.data?.id,
            services: res?.data?.data?.service_name,
          });
        }
      })
      .catch((err) => {
        setError(err?.response?.data?.message);
      
      });
  };

  return (
    <>
      {!error ? (
        <MDBContainer fluid className="py-3">
          {questions.length > 0 ? (
            <div style={{ maxWidth: "90%", margin: "0 auto" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {" "}
                <img
                  width="40px"
                  alt=""
                  src="https://i.imgur.com/0EmvHCj.png"
                />
                <span
                  className="sd-title  sd-question__title"
                  style={{ margin: "20px", fontWeight: 600 }}
                >
                  Please help answer the following questions:
                </span>
              </div>
              <Survey model={survey} />
            </div>
          ) : (
            <div className="d-flex justify-content-center">
              <MDBSpinner
                grow
                style={{ width: "3rem", marginTop: "20%", height: "3rem" }}
              >
                <span className="visually-hidden">Loading...</span>
              </MDBSpinner>
            </div>
          )}
        </MDBContainer>
      ) : (
        <div style={{ color: "red", display:"flex", height:"100vh", alignItems:"center", justifyContent:"center" }}>An Error Occur: Invalid Push Id</div>
      )}
    </>
  );
}

export default Tnps;
