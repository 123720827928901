export default class Question {
  constructor(client) {
    this.client = client;
  }

  createQuestion(body) {
     console.log(body);
     delete body?.placeholder;
     delete body?.character_limit;
     delete body?.id;
     delete body?.min;
     delete body?.max;
     delete body?.det_min;
     delete body?.det_max;
     delete body?.pas_min;
     delete body?.pas_max;
     delete body?.pro_min;
     delete body?.pro_max;
    return this.client.post(`/question`, body);
  }

  getQuestions(services) {
    console.log(services);
    return this.client.post(`/question/combo/service_name`, { service_name: services });
  }

  fetchOneQuestion(id) {
    return this.client.get(`/question/${id}`);
  }
  deleteQuestion(id) {
    return this.client.delete(`/question/${id}`);
  }

  editQuestion(body) {
    const id = body.id;
    console.log(body);
    delete body?.placeholder;
    delete body?.character_limit;
    delete body?.id;
    delete body?.min;
    delete body?.max;
    delete body?.det_min;
    delete body?.det_max;
    delete body?.pas_min;
    delete body?.pas_max;
    delete body?.pro_min;
    delete body?.pro_max;
    console.log(body);
    return this.client.put(`/question/${id}`, body);
  }
}
