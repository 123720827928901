

export default function BotMessageWrapper({ children, time }) {
  const givenDate = new Date(time * 1000).toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });
  return (
    <div  className="d-flex flex-row justify-content-start">
      <img
        src="/temantii.jpg"
        alt="avatar 1"
        style={{ width: "50px", borderRadius:"50%",height: "100%" }}
      />
      <div>
        {children}
        <p className="small ms-3 mb-3 rounded-3 text-muted">
          {time ? givenDate : null}
        </p>
      </div>
    </div>
  );
}
