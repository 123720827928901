export default function UserMessageWrapper({ children, time }) {
  //console.log(time)
  return (
    <div className="d-flex flex-row justify-content-end mb-3 pt-1">
      <div>
        {children}
        <p className="small me-3 mb-3 rounded-3 text-muted d-flex justify-content-end">
          {time}
        </p>
      </div>
      <img
        src="https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
        alt="avatar 1"
        style={{ width: "45px", borderRadius: "50%", height: "100%" }}
      />
    </div>
  );
}
