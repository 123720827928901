import React from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBIcon
} from "mdb-react-ui-kit";

export default function Modal({done, setDone,}) {
 

  const toggleShow = () => {
    setDone(!done)
      window.location.replace("https://www.swsc.co.sz/",);
}

  return (
    <>
      <MDBModal staticBackdrop tabIndex="-1" open={done} setOpen={setDone}>
        <MDBModalDialog centered>
          <MDBModalContent style={{width:"500px"}}>
            <MDBModalHeader>
              <MDBModalTitle>Payment Successful</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={toggleShow}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody style={{ display:"flex", justifyContent:"center",padding: "20px", }}>
              <MDBIcon
                style={{
                  color: "#007fbc",
                  fontSize: "40px",
                  padding: "0 20px",
                }}
                fas
                icon="check-circle"
              />
              <p style={{fontSize:"20px"}}>Your Payment was successful!</p>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="secondary" onClick={toggleShow}>
                Close
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}
