import React from "react";
import { MDBIcon } from "mdb-react-ui-kit";
import { b64toBlob } from "../utils";
function GetDoc({ type, src }) {
  if (type == "document") {
    return (
      <iframe
        scrolling="no"
        style={{ overflow: "hidden", height: "160px", width: "80%" }}
        src={src.base64}
      ></iframe>
    );
  } else if (type == "audio") {
    return <audio src={src.base64} style={{width:"200px"}} controls autoPlay loop />;
  } else if (type == "image") {
    return <img width="150px" src={src.base64} />;
  }
}
function FileMessage({ file, type, incoming }) {
  const linkSource = file.base64;
  console.log(file, type, incoming);
  const blob = b64toBlob(linkSource.split(",")[1], file.mime_type);
  console.log(blob);
  const blobUrl = URL.createObjectURL(blob);
  console.log(blobUrl);
  return (
    <div style={{ marginRight: "20px" }}>
      <GetDoc src={file} type={type} />
      <a
        download={file.filename}
        // target="_blank"
        //rel="noreferrer"
        href={blobUrl}
      >
        {incoming && (
          <div
            style={{
              display: "flex",
              alignItems: "baseline",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              width: "200px",
            }}
          >
            {" "}
            <MDBIcon style={{ marginRight: "10px" }} fas icon="download" />
            <p> Download </p>
          </div>
        )}
      </a>
    </div>
  );
}

export default FileMessage;
