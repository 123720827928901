import React from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
 
} from "mdb-react-ui-kit";
const Error = () => {
  return (
    <div className="App">
      <MDBContainer fluid className="py-3" style={{ backgroundColor: "#eee" }}>
        <MDBRow className="d-flex justify-content-center">
          <MDBCol md="4" lg="4" xl="4">
            <MDBCard
              id="chat2"
              style={{ borderRadius: "15px", margin: "0 auto", height: "95vh" }}
            >
              <div style={{ margin: "50% auto", fontSize:"20px" }}>
                <p>Error 404</p>
                <p>Page Not found</p>
              </div>
            </MDBCard>{" "}
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </div>
  );
};

export default Error;
