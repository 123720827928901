import React from "react";

export default function TemplateMessage({ message, template, handleClick }) {
  
  return (
    <p className="small p-2 ms-3 mb-1 rounded-3">
      <button
      onClick={handleClick}
        style={{
          backgroundColor: "white",
          padding: "7px",
          color: "#007fbc",
          margin: "0",
          borderRadius: "15px",
          border: "1.5px solid #007fbc",
        }}
      >
        {message}:{template[message]}
      </button>
    </p>
  );
}
