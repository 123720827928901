import React, { useEffect, useCallback,useRef, useState } from "react";
import useWebSocket from "react-use-websocket";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardHeader,
  MDBCardBody,
  MDBCardFooter,
  MDBIcon,
  MDBBtn,
} from "mdb-react-ui-kit";
import UserMessageWrapper from "./UserMessageWrapper";
import UserMessage from "./UserMessage";
import BotMessageWrapper from "./BotMessageWrapper";
import BotMessage from "./BotMessage";
import TemplateMessage from "./TemplateMessage";
import ChatBubble from "./ChatBubble";
import { convertToBase64, createWebSocketData } from "../utils";
import { Emoji, EmojiStyle } from "emoji-picker-react";
import FileMessage from "./FileMessage";
import EmojiPicker from "emoji-picker-react";
import { db } from "../db";

const baseUrl = process.env.REACT_APP_CHAT_URL;
console.log(baseUrl)
export default function Card() {
  // const [socketUrl, setSocketUrl] = useState("wss://cb-backend.ewsc.co.sz/clientws/",  {
  //     shouldReconnect: (closeEvent) => true,
  //     reconnectAttempts: 10,
  //     //attemptNumber will be 0 the first time it attempts to reconnect, so this equation results in a reconnect pattern of 1 second, 2 seconds, 4 seconds, 8 seconds, and then caps at 10 seconds until the maximum number of attempts is reached
  //     reconnectInterval: (attemptNumber) =>
  //       Math.min(Math.pow(1.5, attemptNumber) * 1000, 10000),
  //   });
  const [socketUrl, setSocketUrl] = useState(
    `${baseUrl}`,
    {
      shouldReconnect: (closeEvent) => true,
      reconnectAttempts: 10,
      //attemptNumber will be 0 the first time it attempts to reconnect, so this equation results in a reconnect pattern of 1 second, 2 seconds, 4 seconds, 8 seconds, and then caps at 10 seconds until the maximum number of attempts is reached
      reconnectInterval: (attemptNumber) =>
        Math.min(Math.pow(1.5, attemptNumber) * 1000, 10000),
    }
  );
  const [id, setId] = useState("");
  const { sendMessage, lastMessage } = useWebSocket(socketUrl);
  const [template, setTemplate] = useState({});
  const [messages, setMessages] = useState([]);
  const [to, setTo] = useState("");
  const [type, setType] = useState("text");
  const [file, setFile] = useState({
    base64: "",
  });
  const [open, setOpen] = useState(false);
  const [emoji, setEmoji] = useState({});

  const parsedMessage = lastMessage ? JSON.parse(lastMessage.data) : {};
  const ref = useRef(null);

  const scrollToBottom = () => {
    // console.log("scrolled");
    ref?.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
    });
  };

  // components/MessageList.tsx

  useEffect(() => {
    if (messages?.length) {
      ref.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  }, [messages.length]);

  useEffect(() => {
    if (messages?.length > 0 && id) {
      db.messages
        .put(
          {
            session_id: id,
            messages: messages,
          },
          id
        )
        .then((res) => {
          if (res) {
            // console.log("yes");
          }
        })
        .then((res) => {
          if (res) {
            // console.log("yes");
          } else {
            // console.log(res);
          }
        })
        .catch((err) => {
          // console.log(err);
        });
    }
  }, [messages]);

  const checkValidTime = (date) => {
    // Create a new Date object for the given date

    if (!date) return;

    const givenDate = new Date(date * 1000);

    // console.log(givenDate, date);
    // Get the current date
    const currentDate = new Date();

    // Compare the two dates using getTime() method and check if the given date is in the past
    if (givenDate.getTime() < currentDate.getTime()) {
      return false;
    } else {
      // // // console.log("The given date is not in the past.");
      return true;
    }
  };

  const checkForExistingId = () => {
    const idObject = localStorage.getItem("id")
      ? JSON.parse(localStorage.getItem("id"))
      : null;
    //// // console.log(idObject);
    if (checkValidTime(idObject?.expiresIn)) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    // setMessages([]);
    let mesg;
    db.messages.get({ session_id: `${id}` }).then((res) => {
      console.log(res);
      if (res) {
        if (res) {
          mesg = res;
          setMessages(mesg?.messages);
        }
      }
    });
  }, [id]);

  useEffect(() => {
    if (lastMessage && parsedMessage.message == "Connection successful") {
      if (checkForExistingId()) {
        //do more here
        RefreshWebsocket(
          JSON.parse(localStorage.getItem("id")).id,
          parsedMessage?.data?.from
        );
        setId(JSON.parse(localStorage.getItem("id")).id);
        SendWebsocketMessage("ping", JSON.parse(localStorage.getItem("id")).id);
        let mesg;
        db.messages
          .get({ session_id: `${parsedMessage?.data?.from}` })
          .then((res) => {
            if (res) {
              // console.log(res);
              if (res) {
                mesg = res;
                setMessages(mesg?.messages);
              }
            }
          });
      } else {
        //STRINGIFY THE CODE
        setId(parsedMessage?.data?.from);
        SendWebsocketMessage("ping", parsedMessage?.data?.from);
        localStorage.setItem(
          "id",
          JSON.stringify({
            id: parsedMessage?.data?.from,
            expiresIn: parsedMessage?.data?.expiresIn,
          })
        );
      }
    }
    // console.log(lastMessage);
  }, [lastMessage]);

  useEffect(() => {
    if (parsedMessage?.template) {
      setMessages([
        ...messages,
        {
          type: "bot",
          time: parsedMessage?.timestamp,
          messages: [
            parsedMessage?.template.header,
            parsedMessage?.template.body,
            parsedMessage?.template.footer,
          ],
        },
        {
          type: "template",
          time: parsedMessage?.timestamp,
          messages: Object.keys(parsedMessage?.template?.option),
          template: parsedMessage?.template?.option,
        },
      ]);

      if (Object.keys(parsedMessage?.template?.option).length > 0) {
        setTemplate(parsedMessage?.template?.option);
      }
    } else if (parsedMessage?.text) {
      setTo(parsedMessage?.from);
      if (!parsedMessage?.context?.emoji) {
        setMessages([
          ...messages,

          {
            type: "bot",
            time: parsedMessage?.timestamp,
            messages: [parsedMessage?.text?.body],
          },
        ]);
      } else {
        setMessages([
          ...messages,
          {
            file: null,
            incoming: true,
            type: "emoji",
            emoji: parsedMessage?.context?.emoji,
            time: parsedMessage?.timestamp,
          },
        ]);
      }
    } else if (parsedMessage.type && parsedMessage?.type !== "text") {
      const type = parsedMessage?.type;
      const file = parsedMessage[type];
      setMessages([
        ...messages,
        {
          type,
          time: parsedMessage?.timestamp,
          incoming: true,
          file: {
            ...file,
            base64: `data:${file.mime_type};base64, ${file.base64}`,
          },
        },
      ]);
    }
  }, [lastMessage]);
  // // console.log(parsedMessage?.data?.from);

  //// console.log(lastMessage ? JSON.parse(lastMessage?.data) : "");
  useEffect(() => {
    if (emoji?.unified) {
      setMessages([
        ...messages,
        {
          file: null,
          type: "emoji",
          emoji: emoji,
          time: new Date().toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          }),
        },
      ]);

      SendWebSocketEmoji(id, emoji);
    }
  }, [emoji]);
  const SendWebSocketEmoji = useCallback(
    (id, emoji) =>
      sendMessage(
        JSON.stringify({
          from: id,
          to: "",
          type: "text",
          text: {
            preview: true,
            body: "h44",
          },
          emoji,
          context: {
            emoji,
          },
        })
      ),
    []
  );

  const SendWebsocketMessage = useCallback(
    (unitMessage, id) =>
      sendMessage(
        JSON.stringify({
          from: id,
          to: "",
          type: "text",
          text: {
            body: unitMessage,
          },
        })
      ),
    []
  );

  const SendWebSocData = useCallback((data) => sendMessage(data), []);

  const RefreshWebsocket = useCallback(
    (old, newId) =>
      sendMessage(
        JSON.stringify({
          action: "merge",
          old: old,
          new: newId,
        })
      ),
    []
  );
  const [unitMessage, setUnitMessage] = useState("");

  const handleSetType = (type) => {
    if (type == "application/pdf") {
      return setType("document");
    } else if (
      type === "image/jpeg" ||
      type === "image/png" ||
      type === "image/jpg"
    ) {
      return setType("image");
    } else {
      return setType("audio");
    }
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    // console.log(file);
    const filename = file?.name;
    const mime_type = file?.type;
    // console.log(file, mime_type);
    handleSetType(mime_type);
    const base64 = await convertToBase64(file);
    setFile({ base64, filename, mime_type });
    e.target.value = null;
  };

  //// console.log(type);

  const handleSendClick = () => {
    if (!unitMessage && !file.filename) {
      return;
    }

    if (type == "text") {
      SendWebsocketMessage(unitMessage, id);
      // console.log(id);
      //// console.log(messageHistory);
      const temp = messages;
      let lastMessage = temp[temp.length - 1];
      if (!lastMessage) {
        setMessages([
          ...messages,
          {
            type: "user",
            time: new Date().toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            }),
            messages: [unitMessage],
          },
        ]);
      }
      if (lastMessage && lastMessage.type === "user") {
        lastMessage.messages = [...lastMessage.messages, unitMessage];

        temp.pop();
        setMessages([...temp, lastMessage]);
      } else {
        setMessages([
          ...messages,
          {
            type: "user",
            time: new Date().toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            }),
            messages: [unitMessage],
          },
        ]);
      }

      setUnitMessage("");
    } else {
      const myfile = file;

      const data = createWebSocketData({ type, id, ...file });

      setMessages([
        ...messages,
        {
          type,
          file: myfile,
          time: new Date().toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          }),
          incoming: false,
        },
      ]);
      SendWebSocData(data);
      setFile({});
      setType("text");
    }
  };

  const handleTemplateClick = (myid) => {
    const temp = messages;
    let lastMessage = temp[temp.length - 1];
    if (lastMessage.type === "template") {
      //const newMessage = lastMessage.messages.filter((word) => word === myid);

      temp.pop();
      // lastMessage.messages = newMessage;

      setMessages([
        ...temp,
        lastMessage,
        {
          type: "user",
          time: new Date().toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          }),
          messages: [myid],
        },
      ]);
      SendWebsocketMessage(myid, id);
    } else {
      setMessages([
        ...temp,
        {
          type: "user",
          time: new Date().toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          }),
          messages: [myid],
        },
      ]);
      SendWebsocketMessage(myid, id);
    }
  };

  const handleInputChange = (e) => {
    e.preventDefault();
    const myMessage = e.target.value;
    setUnitMessage(myMessage);
  };

  return (
    <MDBContainer fluid className="py-3">
      <MDBRow className="d-flex justify-content-center">
        <MDBCol md="4" lg="4" xl="4">
          <MDBCard id="chat2" style={{ borderRadius: "15px", height: "90vh" }}>
            <MDBCardHeader
              style={{ backgroundColor: "#007fbc", color: "white" }}
              className="d-flex justify-content-between align-items-center p-3"
            >
              <h5
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                className="mb-0"
              >
                {" "}
                <img
                  src="/temantii.jpg"
                  alt="avatar 1"
                  style={{
                    paddingLeft: "10px",
                    width: "55px",
                    borderRadius: "50%",
                    height: "100%",
                  }}
                />
                <span style={{ marginLeft: "10px" }}>Temanti</span>
              </h5>
              {/* <MDBBtn color="primary" size="sm" rippleColor="dark">
                Let's Chat App
              </MDBBtn> */}
            </MDBCardHeader>

            <MDBCardBody className="hidden-scroll">
              <EmojiPicker
                open={open}
                onEmojiClick={(emoji) => {
                  delete emoji.getImageUrl;
                  setEmoji({ ...emoji, incoming: false, type: "text" });
                  setOpen(!open);
                }}
                lazyLoadEmojis
                width="300px"
                height="350px"
              />
              {messages.length > 0 ? (
                messages?.map((m, i) => {
                  if (m?.type == "user") {
                    return (
                      <UserMessageWrapper time={m.time} key={i}>
                        {m.messages?.map((c, i) => [
                          <UserMessage key={i} message={c} />,
                        ])}
                      </UserMessageWrapper>
                    );
                  } else if (m?.type == "bot") {
                    return (
                      <BotMessageWrapper time={m.time} key={i}>
                        {m.messages?.map((c, i) => [
                          <BotMessage key={i} message={c} />,
                        ])}
                      </BotMessageWrapper>
                    );
                  } else if (m?.type == "template" && template[1]) {
                    return (
                      <BotMessageWrapper time={m.time} key={i}>
                        {m.messages?.map((c, i) => [
                          <TemplateMessage
                            key={template[c]}
                            template={m.template}
                            handleClick={() => handleTemplateClick(c)}
                            message={c}
                          />,
                        ])}
                      </BotMessageWrapper>
                    );
                  } else {
                    if (m.incoming === true) {
                      return (
                        <BotMessageWrapper key={i} time={m.time}>
                          {m.file ? (
                            <FileMessage
                              file={m.file}
                              emoji={m?.emoji}
                              incoming={m.incoming}
                              time={m.time}
                              type={m.type}
                            />
                          ) : (
                            <Emoji
                              emojiStyle={EmojiStyle.apple}
                              unified={m?.emoji?.unified}
                            />
                          )}
                        </BotMessageWrapper>
                      );
                    } else {
                      return (
                        <UserMessageWrapper key={i} time={m.time}>
                          {m.file ? (
                            <FileMessage
                              file={m.file}
                              emoji={m?.emoji}
                              time={m.time}
                              type={m.type}
                            />
                          ) : (
                            <Emoji
                              emojiStyle={EmojiStyle.apple}
                              unified={m?.emoji?.unified}
                            />
                          )}
                        </UserMessageWrapper>
                      );
                    }
                  }
                })
              ) : (
                <BotMessageWrapper>
                  <ChatBubble />
                </BotMessageWrapper>
              )}
              <div ref={ref} />
            </MDBCardBody>

            {file?.filename && (
              <MDBCardFooter className="text-muted d-flex justify-content-start align-items-center p-3">
                {" "}
                <div
                  style={{
                    paddingLeft: 10,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  {" "}
                  File: {file.filename}{" "}
                  <div
                    style={{
                      display: "flex",
                      width: "40%",
                      alignItems: "baseline",
                      justifyContent: "space-between",
                    }}
                  >
                    {/* <MDBBtn onClick={handleSendClick}>Upload File</MDBBtn> */}
                    <MDBIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setFile({});
                      }}
                      fas
                      icon="close"
                    />
                  </div>
                </div>
              </MDBCardFooter>
            )}

            <MDBCardFooter className="text-muted d-flex justify-content-start align-items-center p-3">
              <input
                type="text area"
                aria-multiline
                value={unitMessage}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSendClick();
                  }
                }}
                onChange={handleInputChange}
                className="form-control form-control-lg"
                id="exampleFormControlInput1"
                placeholder="Type message"
              ></input>
              {/* <a className="ms-1 text-muted" href="#!">
                <MDBIcon fas icon="paperclip" />
              </a> */}
              <input
                onChange={handleFileUpload}
                id="choose-file"
                accept=".jpeg,.pdf,.jpg,.mp3,.wav,.ogg,.png"
                style={{ display: "none" }}
                type="file"
              />
              <label
                style={{ marginLeft: "10px", cursor: "pointer" }}
                htmlFor="choose-file"
              >
                <MDBIcon fas icon="paperclip" />
              </label>
              {/*  */}
              {/* <a
                onClick={() => {
                  setOpen(!open);
                }}
                className="ms-3 text-muted"
                href="#!"
              >
                <MDBIcon fas icon="smile" />
              </a> */}
              <div
                style={{ cursor: "pointer" }}
                onClick={handleSendClick}
                className="ms-3"
                href="#!"
              >
                <MDBIcon fas icon="paper-plane" />
              </div>
            </MDBCardFooter>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}
