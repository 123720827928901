import React, { useEffect, useRef, useState } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardHeader,
  MDBInput,
  MDBBtn,
  MDBCardBody,
} from "mdb-react-ui-kit";
import { useParams } from "react-router-dom";
import api from "../api";
import Modal from "./Modal";

const Payments = () => {
  const formRef = useRef(null);
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [err, setErr] = useState(null);
  const [redirect, setRedirect] = useState(false);
  const [form, setForm] = useState({});
  const [done, setDone] = useState(false);
  const [payments, setPayments] = useState({
    firstname: "",
    lastname: "",
    expiry: "",
    cardnumber: "",
    cvv: "",
  });

  useEffect(() => {
    const formElement = document.getElementById("frmSubmit");
    if (formElement) {
      formElement.submit();
    }
  }, [form.CHECKSUM]);
  let { trxId } = useParams();
  useEffect(() => {
    getPaymentDetails(trxId);
  }, [trxId]);
  // console.log(trxId);
  const handleChange = (e) => {
    e.preventDefault();
    const value = e.target.value;
    const name = e.target.name;
    setPayments({ ...payments, [name]: value });
  };
  const completePayment = (body) => {
    api.CardPayments.completePyment({ ...body, trx_id: trxId })
      .then((res) => {
        const data = res.data.data;
        console.log(data);
        if (data.isRedirecting === true) {
          setRedirect(true);
          let tags = {};
          data.tags.map((t) => {
            console.log(t["ns2:key"]);
            console.log(t["ns2:value"]);
            tags[t["ns2:key"]] = t["ns2:value"];
          });
          setForm({ url: data.URL, ...tags });
        } else if(res.data.message==="Transaction Approved"){
          setDone(true);
        }else{
          setErr("Unable to process transaction")
        }
        console.log(form);
        //setDone(true);
        setPayments({});
      })
      .catch((err) => {
        setErr(err.response.data.message);
      });
  };
  const getPaymentDetails = (trxId) => {
    if (trxId) {
      api.CardPayments.verifyTransaction(trxId)
        .then((res) => {
          if (res) {
            setLoading(false);
            setPaymentDetails(res?.data?.data);
          }
        })
        .catch((err) => {
          setLoading(false);
          setErr(err?.response?.data?.message);
          setError(true);
        });
    }
  };
  const handlePay = () => {
    // console.log("paid");
    //console.log({ payments });
    completePayment(payments);
  };
  console.log(form);
  // console.log(payments);
  return (
    <div className="App">
      <MDBContainer fluid className="py-3">
        <MDBRow className="d-flex justify-content-center">
          <MDBCol md="4" lg="4" xl="4">
            <MDBCard
              id="chat2"
              style={{ borderRadius: "15px", height: "90vh" }}
            >
              <MDBCardHeader
                style={{ backgroundColor: "#007fbc", color: "white" }}
                className="d-flex justify-content-between align-items-center p-3"
              >
                <h5
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  className="mb-0"
                >
                  {" "}
                  <img
                    src="/temantii.jpg"
                    alt="avatar 1"
                    style={{
                      paddingLeft: "10px",
                      width: "55px",
                      borderRadius: "50%",
                      height: "100%",
                    }}
                  />
                  <span style={{ marginLeft: "10px" }}>
                    Eswatini Water Corp
                  </span>
                </h5>
                {/* <MDBBtn color="primary" size="sm" rippleColor="dark">
                Let's Chat App
              </MDBBtn> */}
              </MDBCardHeader>

              {loading ? (
                <h6>Loading...</h6>
              ) : redirect && form?.url ? (
                <div>
                  <form
                    ref={formRef}
                    action={form.url}
                    name="frmSubmit"
                    id="frmSubmit"
                    method="post"
                    target="iframeID"
                  >
                    <input
                      type="hidden"
                      name="PAYGATE_ID"
                      value={form.PAYGATE_ID}
                    />
                    <input
                      type="hidden"
                      name="PAY_REQUEST_ID"
                      value={form.PAY_REQUEST_ID}
                    />
                    <input
                      type="hidden"
                      name="CHECKSUM"
                      value={form.CHECKSUM}
                    />
                  </form>
                  <iframe
                    style={{ width: "100%", border: "none", height: "500px" }}
                    name="iframeID"
                    id="iframeID"
                  ></iframe>
                </div>
              ) : (
                <MDBCardBody className="hidden-scroll">
                  <h6 className="text-uppercase text-center mb-5">
                    Confirm Payment
                  </h6>

                  {error ? (
                    <h6 className="text-uppercase text-center mb-5">
                      Error: {err}!!
                    </h6>
                  ) : (
                    <>
                      <Modal done={done} setDone={setDone} />
                      <p
                        style={{
                          fontSize: "14px",

                          marginTop: "40px",
                        }}
                      >
                        <b>Payment Details:</b>
                      </p>
                      {err && <p style={{ color: "red" }}>{err}</p>}
                      <p
                        style={{
                          fontSize: "14px",
                          border: "1px  solid grey",
                          padding: "10px",
                          borderRadius: "7px",
                        }}
                      >
                        Amount:
                        {` ${paymentDetails?.currency} ${paymentDetails?.amount}`}
                      </p>
                      <p
                        style={{
                          fontSize: "14px",
                          border: "1px  solid grey",
                          padding: "10px",
                          borderRadius: "7px",
                        }}
                      >
                        Account to recharge:
                        {`  ${paymentDetails?.account_number_to_recieve}`}
                      </p>
                      <p
                        style={{
                          fontSize: "14px",
                          border: "1px  solid grey",
                          padding: "10px",
                          borderRadius: "7px",
                        }}
                      >
                        Account Charged:
                        {`  ${paymentDetails?.bank_account_to_charge}`}
                      </p>
                      <p
                        style={{
                          fontSize: "14px",

                          marginTop: "40px",
                        }}
                      >
                        <b>Please Enter Your Card Details Below:</b>
                      </p>
                      <MDBInput
                        wrapperClass="mb-4"
                        label="First Name"
                        size="lg"
                        name="firstname"
                        placeholder="First Name"
                        value={payments.firstname}
                        onChange={handleChange}
                        id="form1"
                        type="text"
                      />
                      <MDBInput
                        wrapperClass="mb-4"
                        placeholder="Last Name"
                        label="Last Name"
                        name="lastname"
                        value={payments.lastname}
                        onChange={handleChange}
                        size="lg"
                        id="form2"
                        type="text"
                      />
                      <MDBInput
                        wrapperClass="mb-4"
                        label="Card Number"
                        name="cardnumber"
                        placeholder="Card Number"
                        size="lg"
                        value={payments.cardnumber}
                        onChange={handleChange}
                        id="form3"
                        type="text"
                      />
                      <MDBInput
                        wrapperClass="mb-4"
                        label="Expiry Date"
                        placeholder="MM/YYYY"
                        size="lg"
                        name="expiry"
                        id="form4"
                        onChange={handleChange}
                        value={payments.expiry}
                        type="text"
                      />
                      <MDBInput
                        wrapperClass="mb-4"
                        label="CVV"
                        size="lg"
                        name="cvv"
                        onChange={handleChange}
                        value={payments.cvv}
                        id="form5"
                        type="password"
                      />
                      <MDBBtn
                        disabled={
                          payments.cvv === "" ||
                          payments.lastname === "" ||
                          payments.cardnumber === "" ||
                          payments.expiry === "" ||
                          payments.firstname === ""
                        }
                        style={{ backgroundColor: "#007fbc" }}
                        onClick={handlePay}
                        className="mb-4 w-100 gradient-custom-4"
                        size="lg"
                      >
                        Pay
                      </MDBBtn>
                      {err}
                    </>
                  )}
                </MDBCardBody>
              )}
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </div>
  );
};

export default Payments;
