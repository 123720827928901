export const json = {
  elements: [
    {
      displayMode:"buttons",
      type: "rating",
      name: "nps-score",
      title: {
        default:
          "Based on your recent use of pay a bill, how likely are you to recommend EWSC to a friend or colleague?",
        fr: "Sur une échelle de 0 à 10, quelle est la probabilité que vous recommandiez notre produit à un ami ou à un collègue?",
      },
      rateMin: 1,
      rateMax: 10,
      minRateDescription: {
        default: "Very unlikely",
        fr: "Très improbable",
      },
      maxRateDescription: {
        default: "Very likely",
        fr: "Très probable",
      },
      rateDescriptionLocation: "bottom",
    },
    {
      type: "rating",
      name: "nps-score",
      title: {
        default:
          "Based on your recent use of pay a bill, how likely are you to recommend EWSC to a friend or colleague?",
        fr: "Sur une échelle de 0 à 10, quelle est la probabilité que vous recommandiez notre produit à un ami ou à un collègue?",
      },
      rateMin: 0,
      rateMax: 10,
      minRateDescription: {
        default: "Very unlikely",
        fr: "Très improbable",
      },
      maxRateDescription: {
        default: "Very likely",
        fr: "Très probable",
      },
      rateDescriptionLocation: "bottom",
    },
  ],
  showQuestionNumbers: false,
};
