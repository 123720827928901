import React from "react";

export default function UserMessage({ message }) {
  return (
    <p
      className="small p-2 me-3 mb-1 text-white rounded-3 bg-primary"
      style={{ backgroundColor: "#f5f6f7", textWrap:"wrap" }}
    >
      {message}
    </p>
  );
}
