const convertToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

const createWebSocketData = ({ type, id, filename, mime_type, base64 }) => {
  const myfile = base64?.split(",")[1];
  return JSON.stringify({
    from: id,
    to: "",
    type: type,
      "context": { // use context whenever the message is repling to another message
        "message_id": "<MSGID_OF_PREV_MSG>"
    },
    [type]: {
      filename: filename,
      mime_type: mime_type,
      base64: myfile,
      sha256:"jityyuhji67tuygih6687656rtyutyg"
    },
  });
};

 const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
   const byteCharacters = window.atob(b64Data);
   const byteArrays = [];

   for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
     const slice = byteCharacters.slice(offset, offset + sliceSize);

     const byteNumbers = new Array(slice.length);
     for (let i = 0; i < slice.length; i++) {
       byteNumbers[i] = slice.charCodeAt(i);
     }

     const byteArray = new Uint8Array(byteNumbers);
     byteArrays.push(byteArray);
   }

   const blob = new Blob(byteArrays, { type: contentType });
   return blob;
 };

export { convertToBase64, createWebSocketData, b64toBlob };
