import React from "react";

export default function BotMessage({ message }) {
   var urlRegex = /(https?:\/\/[^\s]+)/g;
   const res = message.replace(urlRegex, function (url) {
    // console.log(url);
     return React.createElement("a", { href: url }, "Payment link");
   });
  
   const lines = message.split("\n");

   return (
     <p
       className="small p-2 ms-3 mb-1 rounded-3"
       style={{ backgroundColor: "#f5f6f7", textWrap: "wrap", width: "200px" }}
     >
       {lines?.map((line, index) => (
         <React.Fragment key={index}>
           {line.includes("https") || line.includes("http") ? (
             <a href={line} target="_blank">
               {" "}
               <b> Click this link to proceed.</b>
             </a>
           ) : (
             line
           )}
           <br />
         </React.Fragment>
       ))}
     </p>
   );
}
