export default class Push {
  constructor(client) {
    this.client = client;
  }

  createQuestion(body) {
    return this.client.post(`/push`, body);
  }

  getPushs(service_name) {
    
    return this.client.get(`/push/service_name/${service_name}`);
  }

  fetchOnePush(id) {
    return this.client.get(`/push/${id}`);
  }
  deletePush(id) {
    return this.client.delete(`/push/${id}`);
  }

  editPush(body) {
    const id = body.id;
    return this.client.put(`/push/${id}`, body);
  }
}
