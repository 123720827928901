import axios from "axios";
// import store from "../redux/configureStore";
import Payments from "./payments";
import Response from "./response";
import Question from "./questions";
import Push from "./push";

const baseUrl = process.env.REACT_APP_BASE_URL;
const surveyUrl = "https://tnps.adroyte.net/api/v1/"

//"https://tnps-staging.ewsc.co.sz/api/v1/";

const coreAxiosInstance = axios.create({
  baseURL: `${baseUrl}`,
});
const surveyInstance = axios.create({
  baseURL: `${surveyUrl}`,
});

export default {
  CardPayments: new Payments(coreAxiosInstance),
  question: new Question(surveyInstance),
  push: new Push(surveyInstance),
  response: new Response(surveyInstance)
};
